import Vue from 'vue';
import VueRouter from 'vue-router';
import PageLayout from '@/views/layouts/PageLayout';
import Index from '@/views/pages/Index';
import MyAccount from '@/views/pages/account/MyAccount';
import Login from '@/views/pages/auth/Login';
import Passthrough from '@/components/General/Passthrough';
import store from '@/store/index';
import multiguard from 'vue-router-multiguard';
import Cookies from 'js-cookie';

Vue.use(VueRouter);

const loginMessage = () => {
    Vue.swal.fire(
        'Login Required',
        'Please login again to verify your account',
        'warning'
    );
};

// TODO(This seems to be getting a bit unwieldy)
const isAdminUsingMessagingApp = () => {
    return (
        location.search.indexOf('chat') > -1 ||
        location.search.indexOf('keyword') > -1
    );
};
const isVisitor = () => {
    return Cookies.get('access_token') && !isAdminUsingMessagingApp();
};
const isMessagingApp = () => {
    return location.pathname.indexOf('iwebvisit-touch') > -1;
};

/** Route Guards. These will allow passage to a route or otherwise reroute. */
const ifNotAuthenticated = function (to, from, next) {
    if (!store.getters['Auth/isAuthenticated']) {
        next();
    } else {
        next('/my-account');
    }
};

const ifNotAuthenticatedMessagingApp = function (to, from, next) {
    if (!store.getters['Auth/isAuthenticated']) {
        next();
    } else {
        next('/iwebvisit-touch/messaging');
    }
};

const ifAuthenticated = function (to, from, next) {
    if (store.getters['Auth/isAuthenticated']) {
        if (!store.getters['User/isProfileLoaded']) {
            store.dispatch('User/userRequest').then(() => {
                next();
            });
        } else {
            next();
        }
    } else {
        loginMessage();
        next('/');
    }
};

const ifAuthenticatedMessagingApp = function (to, from, next) {
    if (isVisitor()) {
        if (store.getters['Auth/isAuthenticated']) {
            if (!store.getters['User/isProfileLoaded']) {
                store.dispatch('User/userRequest').then(() => {
                    next();
                });
            } else {
                next();
            }
        } else {
            loginMessage();
            next('/iwebvisit-touch');
        }
    } else {
        next();
    }
};

const ifFacilityReady = function (to, from, next) {
    if (store.getters['Facility/isFacilityReady']) {
        next();
    } else {
        if (store.getters['Facility/isFacilitiesLoaded']) {
            ifHasPreferredFacility(to, from, next);
        } else {
            // Need to load facilities first
            store.dispatch('Facility/facilitiesRequest').then(() => {
                ifHasPreferredFacility(to, from, next);
            });
        }
    }
};

const ifFacilityReadyMessagingApp = function (to, from, next) {
    if (isVisitor()) {
        if (store.getters['Facility/isFacilitiesLoaded']) {
            ifFacilityHasMessagingCapabilities(to, from, next);
        } else {
            // Need to load facilities first
            store.dispatch('Facility/facilitiesRequest').then(() => {
                ifFacilityHasMessagingCapabilities(to, from, next);
            });
        }
    } else {
        next();
    }
};

// Preconditions: ifAuthenticated + loaded facilities
const ifHasPreferredFacility = function (to, from, next) {
    if (store.getters['Facility/preferredFacility']) {
        next();
    } else {
        Vue.swal.fire(
            'Facility Required',
            'Please select a preferred facility before continuing',
            'warning'
        );

        if (to.name === 'my-account') {
            to.params.setFacility = 'true';
            next();
        } else {
            next({ name: 'my-account', params: { setFacility: 'true' } });
        }
    }
};

const ifFacilityHasMessagingCapabilities = function (to, from, next) {
    if (
        !!store.getters['Facility/preferredFacility'] &&
        store.getters['Facility/preferredFacility'].is_messaging_app_enabled
    ) {
        next();
    } else {
        Vue.swal.fire(
            'Facility Required',
            'Please select a preferred facility before continuing',
            'warning'
        );
        next({ name: 'iwebvisit-touch.select-facility' });
    }
};

// This is only used for iWebTouch pages
const ifNotDefaultMemberType = function (to, from, next) {
    if (isVisitor()) {
        if (
            !!store.getters['User/memberType'] &&
            store.getters['User/memberType'].is_default
        ) {
            next();
        } else {
            Vue.swal.fire({
                text: 'The iWebTouch app is only currently allowed for standard visitor types.',
                icon: 'warning',
            });
            next(
                isMessagingApp()
                    ? '/iwebvisit-touch/select-facility'
                    : '/my-account'
            );
        }
    } else {
        next();
    }
};

const ifVerified = function (to, from, next) {
    if (isVisitor()) {
        if (
            store.getters['Facility/isItemsReady'] &&
            store.getters['User/isVerified']
        ) {
            next();
        } else {
            next(
                (isMessagingApp() ? '/iwebvisit-touch' : '') + '/verify-account'
            );
        }
    } else {
        next();
    }
};

const ifRequiresProfileUpdate = function (to, from, next) {
    if (
        store.getters['Facility/isItemsReady'] &&
        (store.state.User.profile.day_phone_type === 'LANDLINE' ||
            (store.state.User.profile.day_phone_type === 'MOBILE' &&
                store.state.User.profile.can_receive_sms)) &&
        store.getters['User/isValidDate'] &&
        store.getters['User/isPrimaryPhoneValid']
    ) {
        next();
    } else {
        const messages = [];
        const hasNotAgreed = !store.state.User.profile.can_receive_sms;

        if (store.state.User.profile.day_phone_type) {
            if (
                store.state.User.profile.day_phone_type === 'MOBILE' &&
                hasNotAgreed
            ) {
                messages.push(
                    '<b>Please read and agree to the terms of our policy regarding SMS messages below</b>'
                );
            }
        } else {
            const extra = hasNotAgreed
                ? 'Please note that for mobile numbers, you will need to read and agree to the terms of our policy regarding SMS messages below. Please submit the form to update your changes.</b>'
                : 'If this is acceptable, please simply submit the form below to update the phone number type, otherwise make the appropriate changes and then submit the form.</b>';
            messages.push(
                '<b>No primary phone number TYPE has been set up for this account. It has been defaulted to "Mobile." ' +
                    extra
            );
        }

        if (!store.getters['User/isPrimaryPhoneValid']) {
            messages.push(
                '<b>Please provide a primary phone number by editing the primary phone field below</b>'
            );
        }

        if (!store.getters['User/isValidDate']) {
            messages.push(
                '<b>Your date of birth is improperly formatted. To fix this, please reselect it from the datepicker ' +
                    'below and submit your changes. Age restrictions may apply.<b>'
            );
        }

        Vue.swal.fire({
            title: 'Profile Update Required',
            icon: 'warning',
            html:
                'The following issues must be resolved before proceeding:<br><br>' +
                messages.join('<br>'),
        });

        if (to.name === 'edit-account') {
            next();
        } else {
            next({ name: 'edit-account' });
        }
    }
};

const ifRequiresId = function (to, from, next) {
    if (
        isVisitor() &&
        store.getters['Facility/isItemsReady'] &&
        store.getters['User/isPhotoIdRequired']
    ) {
        next(
            (isMessagingApp() ? '/iwebvisit-touch/' : '') + 'photo-ids/create'
        );
    } else {
        next();
    }
};

const ifRequiresDocuments = function (to, from, next) {
    if (
        isVisitor() &&
        store.getters['Facility/isItemsReady'] &&
        store.getters['User/isApprovedDocumentsRequired']
    ) {
        next(
            (isMessagingApp() ? '/iwebvisit-touch/' : '') + 'documents/create'
        );
    } else {
        next();
    }
};

// TODO(This will sometimes fail validation on reloading because the data was wiped. Try to fix that for
//       for any pages it is doing this.)
const ifRequiresApproval = function (to, from, next) {
    if (
        store.getters['Facility/isItemsReady'] &&
        store.getters['Facility/isApprovalRequired'] &&
        !store.getters['User/isApproved']
    ) {
        Vue.swal.fire(
            'Approval Required',
            'This facility requires approval before visiting. Please select the inmate below you wish to visit with, ' +
                'click "Continue", then "Request Approval?" Facility administrators will be notified, and if approved, ' +
                'you will receive an email. After receiving the approval email, if you are still not able to enter ' +
                'a web page, please refresh the page and try again.',
            'warning'
        );
        next('/schedule/inmate');
    } else {
        next();
    }
};

const ifDeactivated = function (to, from, next) {
    if (
        isVisitor() &&
        store.getters['Facility/isItemsReady'] &&
        store.getters['Facility/isFacilityDeactivated']
    ) {
        Vue.swal.fire(
            'Facility Lockout',
            'You are not allowed to schedule at this facility',
            'warning'
        );

        if (isMessagingApp()) {
            next('/iwebvisit-touch/select-facility');
        } else {
            next('/my-account');
        }
    } else {
        next();
    }
};

const ifUnderage = function (to, from, next) {
    if (
        isVisitor() &&
        store.getters['Facility/isItemsReady'] &&
        store.getters['User/isUnderage']
    ) {
        Vue.swal.fire(
            'Underage Visitor',
            'This facility does not allow minors to schedule',
            'warning'
        );

        if (isMessagingApp()) {
            next('/iwebvisit-touch/select-facility');
        } else {
            next('/my-account');
        }
    } else {
        next();
    }
};

let routes = [
    {
        path: '/',
        component: PageLayout,
        children: [
            {
                path: '/',
                name: 'index',
                component: Index,
            },

            {
                path: '/about-us',
                name: 'about-us',
                component: () => import('@/views/pages/AboutUs'),
            },

            {
                path: '/visitor-info',
                name: 'visitor-info',
                component: () => import('@/views/pages/VisitorInfo'),
            },

            {
                path: '/confidential',
                name: 'confidential',
                component: () => import('@/views/pages/Confidential'),
            },

            // Visitors using the iWebTouch App
            {
                path: '/iwebvisit-touch',
                component: Passthrough,
                children: [
                    {
                        path: '/',
                        name: 'iwebvisit-touch.index',
                        component: () =>
                            import(
                                '@/views/pages/messaging-app/static/IWebVisitTouch.vue'
                            ),
                    },
                    {
                        path: 'usage-policy',
                        name: 'iwebvisit-touch.usage-policy',
                        component: () =>
                            import(
                                '@/views/pages/messaging-app/static/UsagePolicy.vue'
                            ),
                    },
                    {
                        path: 'refund-policy',
                        name: 'iwebvisit-touch.refund-policy',
                        component: () =>
                            import(
                                '@/views/pages/messaging-app/static/RefundPolicy.vue'
                            ),
                    },
                    {
                        path: 'recommendations',
                        name: 'iwebvisit-touch.recommendations',
                        component: () =>
                            import(
                                '@/views/pages/messaging-app/static/Recommendations.vue'
                            ),
                    },
                    {
                        path: 'download-app',
                        name: 'iwebvisit-touch.download-app',
                        component: () =>
                            import(
                                '@/views/pages/messaging-app/static/DownloadApp.vue'
                            ),
                    },
                    // Note that this is exactly the same as the normal site's Acceptable Use
                    // page, but it's in iwebvisit-touch subdirectory. This just works easier
                    // to keep them within the app when routing.
                    {
                        path: 'acceptable-use-policy',
                        name: 'iwebvisit-touch.acceptable-use-policy',
                        component: () =>
                            import('@/views/pages/AcceptableUsePolicy.vue'),
                    },
                    // Same as above. The following routes are already pages for
                    // the main site. We let the app display them, and use the
                    // iwebvisit-touch subdirectory to help us navigate within the
                    // app.
                    {
                        path: 'create-account',
                        name: 'iwebvisit-touch.create-account',
                        component: () =>
                            import('@/views/pages/account/CreateAccount'),
                        beforeEnter: ifNotAuthenticatedMessagingApp,
                    },
                    {
                        path: 'auth',
                        component: Passthrough,
                        children: [
                            {
                                path: 'login',
                                props: true,
                                name: 'iwebvisit-touch.auth.login',
                                component: Login,
                                beforeEnter: ifNotAuthenticatedMessagingApp,
                            },
                            {
                                path: 'password/email',
                                name: 'iwebvisit-touch.auth.forgotten-password',
                                component: () =>
                                    import(
                                        '@/views/pages/auth/ForgottenPassword'
                                    ),
                                beforeEnter: ifNotAuthenticatedMessagingApp,
                            },
                            {
                                path: 'password/reset/:token',
                                name: 'iwebvisit-touch.password-reset',
                                component: () =>
                                    import('@/views/pages/auth/PasswordReset'),
                            },
                        ],
                    },
                    {
                        path: 'verify-account',
                        name: 'iwebvisit-touch.verify-account',
                        component: () =>
                            import('@/views/pages/account/VerifyAccount'),
                        beforeEnter: multiguard([
                            ifAuthenticatedMessagingApp,
                            ifFacilityReadyMessagingApp,
                        ]),
                    },
                    {
                        path: 'my-account/edit',
                        name: 'iwebvisit-touch.edit-account',
                        component: () =>
                            import('@/views/pages/account/EditAccount'),
                        beforeEnter: multiguard([
                            ifAuthenticatedMessagingApp,
                            ifFacilityReadyMessagingApp,
                        ]),
                    },
                    {
                        path: 'transaction-logs',
                        name: 'iwebvisit-touch.transaction-logs',
                        component: () =>
                            import(
                                '@/views/pages/messaging-app/TransactionLogs'
                            ),
                        beforeEnter: multiguard([
                            ifAuthenticatedMessagingApp,
                            ifFacilityReadyMessagingApp,
                        ]),
                    },
                    {
                        path: 'select-facility',
                        name: 'iwebvisit-touch.select-facility',
                        component: () =>
                            import('@/views/pages/account/SelectFacility'),
                        beforeEnter: ifAuthenticatedMessagingApp,
                    },
                    {
                        path: 'credit-payment',
                        name: 'iwebvisit-touch.credit-payment',
                        props: true,
                        component: () =>
                            import('@/views/pages/messaging-app/CreditPayment'),
                        beforeEnter: multiguard([
                            ifAuthenticatedMessagingApp,
                            ifFacilityReadyMessagingApp,
                            ifNotDefaultMemberType,
                        ]),
                    },
                ],
            },
            {
                path: '/support',
                name: 'support',
                component: () => import('@/views/pages/Support'),
            },
            {
                path: '/testimonials',
                name: 'testimonials',
                component: () => import('@/views/pages/Testimonials'),
            },
            {
                path: '/system-requirements',
                name: 'system-requirements',
                component: () => import('@/views/pages/SystemRequirements'),
            },
            {
                path: '/refund-policy',
                name: 'refund-policy',
                component: () => import('@/views/pages/RefundPolicy'),
            },
            {
                path: '/acceptable-use-policy',
                name: 'acceptable-use-policy',
                component: () =>
                    import('@/views/pages/AcceptableUsePolicy.vue'),
            },
            {
                path: '/test-my-equipment',
                name: 'test-my-equipment',
                component: () => import('@/views/pages/TestMyEquipment'),
            },
            {
                path: '/approvals/:action',
                props: true,
                name: 'approvals',
                component: () => import('@/views/pages/approval/Approval'),
            },
            {
                path: '/',
                name: 'auth',
                component: Passthrough,
                children: [
                    {
                        path: '/login',
                        props: true,
                        name: 'auth.login',
                        component: Login,
                        beforeEnter: ifNotAuthenticated,
                    },
                    {
                        path: '/password/email',
                        name: 'auth.forgotten-password',
                        component: () =>
                            import('@/views/pages/auth/ForgottenPassword'),
                        beforeEnter: ifNotAuthenticated,
                    },
                    {
                        path: '/password/reset/:token',
                        name: 'password-reset',
                        component: () =>
                            import('@/views/pages/auth/PasswordReset'),
                    },
                ],
            },

            {
                path: '/create-account',
                name: 'create-account',
                component: () => import('@/views/pages/account/CreateAccount'),
                beforeEnter: ifNotAuthenticated,
            },
            {
                path: '/verify-account',
                name: 'verify-account',
                component: () => import('@/views/pages/account/VerifyAccount'),
                beforeEnter: ifAuthenticated,
            },
            {
                path: '/my-account/edit',
                name: 'edit-account',
                component: () => import('@/views/pages/account/EditAccount'),
                beforeEnter: multiguard([
                    ifAuthenticated,
                    ifFacilityReady,
                    ifRequiresProfileUpdate,
                ]),
            },
            // Make sure to always keep this one BELOW edit-account, or change edit's name
            {
                path: '/my-account/:setFacility?',
                name: 'my-account',
                props: true,
                component: MyAccount,
                beforeEnter: multiguard([ifAuthenticated, ifFacilityReady]),
            },
            {
                path: '/schedule/inmate',
                name: 'schedule-inmate',
                component: () =>
                    import('@/views/pages/schedule/ScheduleInmate'),
                beforeEnter: multiguard([
                    ifAuthenticated,
                    ifFacilityReady,
                    ifDeactivated,
                    ifRequiresProfileUpdate,
                    ifUnderage,
                    ifVerified,
                    ifRequiresId,
                    ifRequiresDocuments,
                ]),
            },
            {
                path: '/schedule/datetime',
                name: 'schedule-datetime',
                props: true,
                component: () =>
                    import('@/views/pages/schedule/ScheduleDateTime'),
                beforeEnter: multiguard([
                    ifAuthenticated,
                    ifFacilityReady,
                    ifDeactivated,
                    ifRequiresProfileUpdate,
                    ifUnderage,
                    ifVerified,
                    ifRequiresId,
                    ifRequiresDocuments,
                    ifRequiresApproval,
                ]),
            },
            {
                path: '/schedule/payment',
                name: 'schedule-payment',
                props: true,
                component: () =>
                    import('@/views/pages/schedule/SchedulePayment'),
                beforeEnter: multiguard([
                    ifAuthenticated,
                    ifFacilityReady,
                    ifDeactivated,
                    ifRequiresProfileUpdate,
                    ifUnderage,
                    ifVerified,
                    ifRequiresId,
                    ifRequiresDocuments,
                    ifRequiresApproval,
                ]),
            },
        ],
    },

    // Terminal schedules
    {
        path: '/schedules/:token/terminals/:id',
        props: true,
        name: 'schedules',
        component: () => import('@/views/pages/terminals/Schedules'),
    },
    // I would have normally put these in 'iwebvisit-touch' above, but these don't use
    // the standard header/footer section that most of the site uses.
    {
        path: '/iwebvisit-touch',
        component: Passthrough,
        children: [
            {
                path: 'messaging',
                name: 'iwebvisit-touch.messaging.index',
                props: true,
                component: () => import('@/views/pages/messaging-app/Index'),
                beforeEnter: multiguard([
                    ifAuthenticatedMessagingApp,
                    ifFacilityReadyMessagingApp,
                    ifDeactivated,
                    ifUnderage,
                    ifVerified,
                    ifRequiresId,
                    ifRequiresDocuments,
                    ifNotDefaultMemberType,
                ]),
            },
            {
                path: 'messaging/messages',
                name: 'iwebvisit-touch.messaging.messages',
                props: true,
                component: () => import('@/views/pages/messaging-app/Messages'),
                beforeEnter: multiguard([
                    ifAuthenticatedMessagingApp,
                    ifFacilityReadyMessagingApp,
                    ifDeactivated,
                    ifUnderage,
                    ifVerified,
                    ifRequiresId,
                    ifRequiresDocuments,
                    ifNotDefaultMemberType,
                ]),
            },
            // Inmate Contacts
            {
                path: 'inmate-contacts',
                component: Passthrough,
                children: [
                    {
                        path: '/',
                        name: 'iwebvisit-touch.inmate-contacts.index',
                        props: true,
                        component: () =>
                            import(
                                '@/views/pages/messaging-app/inmate-contacts/Index'
                            ),
                    },
                    {
                        path: 'create',
                        name: 'iwebvisit-touch.inmate-contacts.create',
                        props: true,
                        component: () =>
                            import(
                                '@/views/pages/messaging-app/inmate-contacts/Create'
                            ),
                    },
                    {
                        path: 'update/:id',
                        name: 'iwebvisit-touch.inmate-contacts.update',
                        props: true,
                        component: () =>
                            import(
                                '@/views/pages/messaging-app/inmate-contacts/Update'
                            ),
                    },
                ],
            },
            // Photo ids / approved docs, if using iWebTouch. We don't actually use these
            // as of 2024-07-08, but I wouldn't be surprised if we do eventually, so going
            // ahead and adding the routes.
            {
                path: '/',
                component: Passthrough,
                children: [
                    {
                        path: 'photo-ids/create',
                        name: 'iwebvisit-touch.approval.photo-ids.create',
                        props: true,
                        component: () =>
                            import('@/views/pages/approval/photo-ids/Create'),
                        beforeEnter: ifAuthenticatedMessagingApp,
                    },
                    {
                        path: 'photo-ids/update',
                        name: 'iwebvisit-touch.approval.photo-ids.update',
                        props: true,
                        component: () =>
                            import('@/views/pages/approval/photo-ids/Update'),
                        beforeEnter: ifAuthenticatedMessagingApp,
                    },
                    {
                        path: 'documents/create',
                        name: 'iwebvisit-touch.approval.documents.create',
                        props: true,
                        component: () =>
                            import('@/views/pages/approval/documents/Create'),
                        beforeEnter: ifAuthenticatedMessagingApp,
                    },
                    {
                        path: 'documents/update',
                        name: 'iwebvisit-touch.approval.documents.update',
                        props: true,
                        component: () =>
                            import('@/views/pages/approval/documents/Update'),
                        beforeEnter: ifAuthenticatedMessagingApp,
                    },
                ],
            },
        ],
    },

    // The visit page
    {
        path: '/visit-page/:id',
        name: 'visit-page',
        props: true,
        component: () => import('@/views/pages/visit-page/VisitPage'),
    },
    // Test Visits
    {
        path: '/testvisit',
        name: 'test-visit',
        component: () => import('@/views/pages/test-visit/TestVisit'),
    },
    // Photo ids / approved docs.
    {
        path: '/',
        name: 'approval',
        component: Passthrough,
        children: [
            {
                path: '/photo-ids/create',
                name: 'approval.photo-ids.create',
                props: true,
                component: () =>
                    import('@/views/pages/approval/photo-ids/Create'),
                beforeEnter: ifAuthenticated,
            },

            {
                path: '/photo-ids/update',
                name: 'approval.photo-ids.update',
                props: true,
                component: () =>
                    import('@/views/pages/approval/photo-ids/Update'),
                beforeEnter: ifAuthenticated,
            },

            {
                path: '/documents/create',
                name: 'approval.documents.create',
                props: true,
                component: () =>
                    import('@/views/pages/approval/documents/Create'),
                beforeEnter: ifAuthenticated,
            },

            {
                path: '/documents/update',
                name: 'approval.documents.update',
                props: true,
                component: () =>
                    import('@/views/pages/approval/documents/Update'),
                beforeEnter: ifAuthenticated,
            },
        ],
    },
    // Inmate Authentication Routes
    {
        path: '/auth/inmates',
        component: Passthrough,
        children: [
            {
                path: 'register/token/:token',
                name: 'auth.inmates.register',
                props: true,
                component: () => import('@/views/pages/auth/inmates/Register'),
            },
            {
                path: 'password/email/token/:token',
                name: 'auth.inmates.forgotten-password',
                props: true,
                component: () =>
                    import('@/views/pages/auth/inmates/ForgottenPassword'),
            },
            {
                path: ':inmate/password/reset/token/:token',
                name: 'auth.inmates.change-password',
                props: true,
                component: () =>
                    import('@/views/pages/auth/inmates/ChangePassword'),
            },
        ],
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    // eslint-disable-next-line no-unused-vars
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            if (to.hash) {
                return {
                    selector: to.hash,
                };
            } else {
                return { x: 0, y: 0 };
            }
        }
    },
});

export default router;
